import React from "react";

const BATCLogo = () => {
  return (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 695.8 41"
    xmlSpace="preserve"
  >
    <g id="BATC">
      <path id="B" d="M12.4,4.7v30.9c-1.7,0.1-3.8,0.2-6.2,0.4v-0.5c2.5-0.7,3.8-3.3,3.8-7.9V12.2c0-4.1-1.2-6.5-3.8-7.3V4.4
		C8.2,4.6,10.3,4.7,12.4,4.7z M14.5,35.6V4.7l5-0.4c2.5,0,4.5,0.8,5.9,2.3c1.4,1.6,2.2,3.5,2.2,5.9c0,3-1.3,5.2-3.9,6.5
		c4.2,1,6.3,3.7,6.3,8.2c0,2.3-0.7,4.3-2.1,6c-1.4,1.7-3.7,2.6-6.8,2.6c-1.2,0-2.4-0.1-3.8-0.2C16.2,35.6,15.2,35.6,14.5,35.6z
		 M16.9,12.7v5.9h2.3c1.9,0,3.3-0.5,4.3-1.5c1-1,1.5-2.5,1.5-4.4c0-2-0.5-3.5-1.6-4.5c-1.1-1-2.4-1.5-3.9-1.5c-0.8,0-1.5,0.2-2,0.6
		C17.1,8.3,16.9,10.1,16.9,12.7z M19.9,20.7h-2.9V26c0,3.7,0.4,6,1.2,7c0.7,0.5,1.7,0.8,3.1,0.8c2.3,0,3.9-0.7,4.9-2
		c0.9-1.3,1.4-2.9,1.4-4.7c0-1.4-0.3-2.6-0.9-3.7c-0.6-1-1.4-1.8-2.3-2.2C23.3,20.9,21.8,20.7,19.9,20.7z M28,18.2
		c1.2-1.6,1.8-3.4,1.8-5.6c0-1.7-0.3-3.2-1-4.6c-0.7-1.4-1.5-2.3-2.4-2.8c1.9,0.4,3.4,1.2,4.5,2.5c1,1.3,1.6,2.7,1.6,4.2
		c0,1.4-0.4,2.7-1.3,3.9C30.3,17,29.3,17.8,28,18.2z M29.5,34.8c1.8-1.9,2.7-4.3,2.7-7.3c0-3.2-0.9-5.7-2.8-7.7
		c3.7,1.3,5.6,3.8,5.6,7.4c0,1.8-0.5,3.4-1.6,4.7C32.4,33.4,31.1,34.3,29.5,34.8z"/>
      <path id="I" d="M45.9,4.7v30.9c-1.4,0-3.5,0.1-6.4,0.4v-0.5c1.5-0.4,2.5-1.2,3.1-2.5c0.6-1.3,0.9-3.6,0.9-7V12.8
		c0-2.5-0.3-4.4-0.9-5.6C42,6,41,5.2,39.5,4.9V4.4C41.9,4.6,44,4.7,45.9,4.7z M48.1,35.6V4.7c2.4,0,4.6-0.1,6.4-0.4v0.5
		c-1.2,0.2-2.1,0.8-2.9,1.9c-0.8,1.1-1.2,3.3-1.2,6.7v14c0,3,0.4,5,1.2,6.1c0.8,1,1.8,1.7,2.8,2v0.5C52.7,35.7,50.6,35.6,48.1,35.6z
		"/>
      <path id="G" d="M65.4,7.4c-2.4,3.4-3.6,7.7-3.6,12.8c0,1.2,0.1,2.9,0.4,5.2c0.3,2.2,1.1,4.5,2.5,6.9c-3.8-3-5.7-7-5.7-12.1
		c0-3.1,0.6-5.8,1.9-7.9C62.3,10.1,63.7,8.5,65.4,7.4z M84.6,19.5v14.8c-3.1,1.3-5.8,1.9-8,1.9c-3.8,0-6.8-1.2-9.2-3.8
		c-1.2-1.3-2-2.9-2.6-4.8c-0.6-1.9-0.9-4.2-0.9-6.7C64,9.7,68.6,4.1,78,4.1c3.4,0,6.8,0.6,10,1.7c0,1.8,0,3.2,0.1,4.2
		c0.1,0.8,0.1,1.9,0.1,3.1h-0.6c-0.5-2.1-1.6-3.8-3.3-5c-1.8-1.2-3.9-1.8-6.4-1.8c-7.6,0-11.4,4.6-11.4,13.7
		c0,9.3,3.2,13.9,9.7,13.9c2.6,0,4.4-0.6,5.5-1.8c0.4-0.6,0.5-1.8,0.5-3.6v-4c0-2.8-1-4.5-3.1-4.9v-0.5c0.7,0,1.7,0.1,3,0.2
		C83.1,19.4,84,19.4,84.6,19.5z M86.8,33.6V19.5c0.9,0,1.8-0.1,2.6-0.2c1.3-0.1,2.1-0.2,2.5-0.2v0.5c-1.8,0.7-2.7,2.6-2.7,5.7v7.4
		c-0.1,0-0.5,0.1-1.1,0.4c-0.3,0.1-0.6,0.2-0.8,0.2C87.1,33.5,86.9,33.5,86.8,33.6z"/>
      <path id="A" d="M133.3,28.5l-2.6-5.4h-11l-2.8,5.4l-0.1,0.4c0,0-0.2,0.3-0.4,0.8c-0.4,0.8-0.6,1.7-0.6,2.5c0,1.5,0.8,2.5,2.5,3.2
		v0.5c-0.3,0-0.9,0-1.7-0.1c-1.2-0.1-2.2-0.2-3.1-0.2c-0.8,0-1.9,0.1-3.1,0.2c-0.8,0.1-1.4,0.1-1.7,0.1v-0.5
		c2.1-1.1,4.1-3.3,5.9-6.8l9.7-18.9c-0.7-1.5-1.8-2.7-3.1-3.5c-1.3-0.8-2.8-1.3-4.6-1.3V4.4h1c0.9,0,2.2,0.1,3.8,0.2
		c1.7,0.1,2.8,0.2,3.3,0.2l14.8,30.9c-1.9,0-4.2,0.2-6.8,0.4v-0.5c1.5-0.3,2.2-1.1,2.2-2.4C134.8,32,134.3,30.5,133.3,28.5z
		 M120.8,20.9h8.8l-4.2-8.9L120.8,20.9z M141.7,35.6L127,4.7h2.9c0.4,1,0.9,2.3,1.6,3.8c0.8,1.7,1.2,2.6,1.3,3
		c0.1,0.2,0.2,0.4,0.3,0.6l6.9,14.3c2.4,5,5,8,7.7,9v0.5C144.9,35.7,143,35.6,141.7,35.6z"/>
      <path id="DS_00000002366857395003903570000000655842897744213383_" d="M161.8,35.9V41h-2.1v-4.9c-1.3,0-2.8-0.2-4.6-0.6
		c-2-0.4-3.4-0.6-4-0.6c-0.2,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.3,0c-0.2,0-0.3-0.2-0.3-0.6c0-0.8,0-2-0.1-3.4c0-2-0.1-3-0.1-3
		c0-0.7,0-1.3,0.1-1.8h0.6c0.2,2.1,1.1,3.9,2.7,5.5c1.6,1.6,3.7,2.5,6.4,2.5V21.6c-6.7-2.4-10.1-5.6-10.1-9.6c0-2.1,1-3.9,3.1-5.5
		c-0.8,1.4-1.3,2.8-1.3,4.3c0,2.1,0.7,3.8,2.2,5.1c1.4,1.3,4,2.6,7.6,4c1.6,0.6,2.9,1.1,3.9,1.7c0.9,0.5,1.7,1.4,2.3,2.6
		c0.7,1.2,1,2.4,1,3.8c0,1.8-0.5,3.4-1.6,4.8C165.8,34.2,164.1,35.2,161.8,35.9z M159.7,3.7V0h2.1v3.5c2.5,0,5.2,0.6,8.2,1.8
		c0,0.4,0,1.1,0.1,2.1c0.1,1.9,0.1,3.3,0.1,4.1c0,0,0,0.3-0.1,1h-0.6c-0.2-2.1-1-3.7-2.5-5c-1.5-1.2-3.2-1.8-5.2-1.8v10.3
		c3.8,1.2,6.4,2.6,7.7,4.2c1.3,1.6,2,3.4,2,5.5c0,1.9-0.7,3.6-2.1,5.2c0.6-1.2,0.8-2.4,0.8-3.6c0-1.6-0.4-3-1.1-4.2
		c-0.8-1.2-1.7-2.2-2.9-2.9c-1.1-0.7-3-1.5-5.8-2.6c-3.2-1.2-5.2-2.4-6-3.6c-0.8-1.2-1.2-2.4-1.2-3.7c0-1.8,0.7-3.3,2-4.5
		C156.6,4.6,158.1,3.9,159.7,3.7z M159.7,15V5.6c-1.4,0.2-2.4,0.8-3.2,1.7c-0.8,0.9-1.1,1.8-1.1,2.9C155.4,12.4,156.9,14,159.7,15z
		 M161.8,22.4v11.5c3-1.1,4.5-3.1,4.5-5.9C166.3,25.5,164.8,23.6,161.8,22.4z"/>
      <path id="DS" d="M186.2,35.9V41h-2.1v-4.9c-1.3,0-2.8-0.2-4.6-0.6c-2-0.4-3.4-0.6-4-0.6c-0.2,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.3,0
		c-0.2,0-0.3-0.2-0.3-0.6c0-0.8,0-2-0.1-3.4c0-2-0.1-3-0.1-3c0-0.7,0-1.3,0.1-1.8h0.6c0.2,2.1,1.1,3.9,2.7,5.5
		c1.6,1.6,3.7,2.5,6.4,2.5V21.6C177.4,19.2,174,16,174,12c0-2.1,1-3.9,3.1-5.5c-0.8,1.4-1.3,2.8-1.3,4.3c0,2.1,0.7,3.8,2.2,5.1
		c1.4,1.3,4,2.6,7.6,4c1.6,0.6,2.9,1.1,3.9,1.7c0.9,0.5,1.7,1.4,2.3,2.6c0.7,1.2,1,2.4,1,3.8c0,1.8-0.5,3.4-1.6,4.8
		C190.2,34.2,188.5,35.2,186.2,35.9z M184.1,3.7V0h2.1v3.5c2.5,0,5.2,0.6,8.2,1.8c0,0.4,0,1.1,0.1,2.1c0.1,1.9,0.1,3.3,0.1,4.1
		c0,0,0,0.3-0.1,1h-0.6c-0.2-2.1-1-3.7-2.5-5c-1.5-1.2-3.2-1.8-5.2-1.8v10.3c3.8,1.2,6.4,2.6,7.7,4.2c1.3,1.6,2,3.4,2,5.5
		c0,1.9-0.7,3.6-2.1,5.2c0.6-1.2,0.8-2.4,0.8-3.6c0-1.6-0.4-3-1.1-4.2c-0.8-1.2-1.7-2.2-2.9-2.9c-1.1-0.7-3-1.5-5.8-2.6
		c-3.2-1.2-5.2-2.4-6-3.6c-0.8-1.2-1.2-2.4-1.2-3.7c0-1.8,0.7-3.3,2-4.5C181,4.6,182.5,3.9,184.1,3.7z M184.1,15V5.6
		c-1.4,0.2-2.4,0.8-3.2,1.7c-0.8,0.9-1.1,1.8-1.1,2.9C179.8,12.4,181.2,14,184.1,15z M186.2,22.4v11.5c3-1.1,4.5-3.1,4.5-5.9
		C190.7,25.5,189.2,23.6,186.2,22.4z"/>
      <path id="T" d="M219.5,4.7h9.7v30.9h-0.9c-1.3,0-3.2,0.1-5.7,0.4v-0.5c1.6-0.4,2.7-1.2,3.3-2.5c0.6-1.2,0.9-3.3,0.9-6.2v-20h-7.5
		c-1.9,0-3.2,0.4-4,1.2c-0.8,0.8-1.4,2.3-2,4.4h-0.7c0-0.3,0-0.5,0-0.8c0-0.4,0-0.9,0.1-1.5c0.2-2.1,0.4-4,0.4-5.7
		c1,0.1,2.3,0.2,3.9,0.3L219.5,4.7z M232.1,35.6h-0.8V4.7h10.5c1.9,0,3.8-0.1,5.7-0.4c0,0.4,0,1-0.1,2c-0.1,0.9-0.1,2-0.1,3.4
		c0,0.9,0,1.7-0.1,2.6h-0.7c0-1.6-0.4-2.9-1.2-4c-0.8-1.1-2.4-1.6-5-1.6h-6.7v21c0,2.4,0.3,4.2,1,5.4c0.7,1.2,1.7,2,3.2,2.3v0.5
		C234.5,35.7,232.5,35.6,232.1,35.6z"/>
      <path id="O" d="M255.4,7.5c-2.1,3.3-3.2,7.6-3.2,12.7c0,4.7,1,8.8,3.1,12.2c-3.9-2.6-5.9-6.8-5.9-12.5
		C249.3,14,251.3,9.9,255.4,7.5z M280,20c0,3.6-0.5,6.6-1.6,9.1c-1.1,2.5-2.6,4.3-4.5,5.4c-1.9,1.2-4.2,1.8-6.7,1.8
		c-4.1,0-7.2-1.5-9.4-4.4c-2.2-2.9-3.3-6.9-3.3-12c0-5.3,1.1-9.4,3.4-12.2c2.3-2.8,5.4-4.2,9.3-4.2c4,0,7.2,1.4,9.5,4.2
		C278.9,10.6,280,14.6,280,20z M277.3,20c0-9.4-3.4-14-10.2-14c-6.7,0-10,4.8-10,14.4c0,9,3.4,13.5,10.1,13.5
		C273.9,33.9,277.3,29.3,277.3,20z M279,32.6c2.1-2.9,3.2-7.2,3.2-13.1c0-5.1-1.1-9.1-3.2-12c4,2.4,6,6.5,6,12.3
		C285,25.6,283,29.8,279,32.6z"/>
      <path id="Y" d="M287.6,4.7l15.2,16.1v14.8h-0.7c-1.7,0-3.7,0.1-5.9,0.4v-0.5c1.3-0.3,2.3-0.9,3-1.9c0.7-1,1.1-2.8,1.1-5.6v-6.4
		l-9.3-9.8c-3.6-3.7-6.8-6-9.6-6.8V4.4c1.6,0.2,3.4,0.4,5.3,0.4H287.6z M305.1,35.6V20L290.5,4.7h0.8c2.3,0,4.7-0.1,7.2-0.4v0.5
		c-1.2,0.3-1.7,0.9-1.7,1.6c0,1,1.2,2.7,3.5,5.2l6.2,6.5l5.8-6.8c1.3-1.5,1.9-2.9,1.9-4c0-1.3-0.7-2.2-2-2.5V4.4
		c0.5,0,1.7,0.1,3.6,0.2c0.8,0.1,1.5,0.1,2.1,0.1c0.3,0,2.1-0.1,5.2-0.4v0.5c-1.5,0.4-2.7,1-3.6,1.8c-0.9,0.8-1.9,1.8-3,3.2
		c-0.1,0.1-0.2,0.3-0.4,0.5l-8.5,10v7.4c0,4.5,1.4,7.1,4.2,7.6v0.5c-3.6-0.2-5.4-0.4-5.5-0.4H305.1z"/>
      <path id="C" d="M345.8,8.8c-2.2,2.9-3.3,6.7-3.3,11.5c0,5.6,1.4,9.8,4.1,12.6c-2.1-0.9-3.8-2.5-5-4.8c-1.2-2.3-1.8-4.9-1.8-7.7
		c0-2,0.3-3.8,1-5.4c0.6-1.6,1.3-2.9,2.2-3.8C343.6,10.2,344.6,9.5,345.8,8.8z M370.1,29.2h0.6l-0.9,5c-1.8,0.7-3.6,1.2-5.2,1.6
		c-1.7,0.3-3.3,0.5-4.9,0.5c-4.7,0-8.4-1.4-11.1-4.1c-2.7-2.7-4-6.7-4-11.9c0-5.2,1.4-9.2,4.3-11.9c2.9-2.7,6.6-4.1,11.3-4.1
		c3.4,0,6.8,0.6,10.3,1.8c-0.2,2.5-0.2,3.9-0.2,4.4c0,0.4,0,0.8,0,1.2v0.7c0,1,0,2-0.1,2.9h-0.5c-1.2-5.9-4.6-8.8-10.1-8.8
		c-3.8,0-6.7,1.2-9,3.7c-2.2,2.4-3.3,5.9-3.3,10.5c0,4,1,7.3,3.1,9.8c2,2.5,5.1,3.8,9.2,3.8c2.3,0,4.3-0.4,6.2-1.3
		C367.5,31.8,369,30.6,370.1,29.2z"/>
      <path id="O_00000052091514144274886650000002512678020548315537_" d="M380.5,7.5c-2.1,3.3-3.2,7.6-3.2,12.7c0,4.7,1,8.8,3.1,12.2
		c-3.9-2.6-5.9-6.8-5.9-12.5C374.4,14,376.4,9.9,380.5,7.5z M405.2,20c0,3.6-0.5,6.6-1.6,9.1c-1.1,2.5-2.6,4.3-4.5,5.4
		c-1.9,1.2-4.2,1.8-6.7,1.8c-4.1,0-7.2-1.5-9.4-4.4c-2.2-2.9-3.3-6.9-3.3-12c0-5.3,1.1-9.4,3.4-12.2c2.3-2.8,5.4-4.2,9.3-4.2
		c4,0,7.2,1.4,9.5,4.2C404,10.6,405.2,14.6,405.2,20z M402.4,20c0-9.4-3.4-14-10.2-14c-6.7,0-10,4.8-10,14.4c0,9,3.4,13.5,10.1,13.5
		C399,33.9,402.4,29.3,402.4,20z M404.1,32.6c2.1-2.9,3.2-7.2,3.2-13.1c0-5.1-1.1-9.1-3.2-12c4,2.4,6,6.5,6,12.3
		C410.2,25.6,408.1,29.8,404.1,32.6z"/>
      <path id="L" d="M413.8,4.4l6.2,0.4v30.9h-1.8c-1.1,0-2.6,0.1-4.4,0.4v-0.5c1.4-0.6,2.4-1.5,3-2.7c0.5-1.3,0.8-3.3,0.8-6.1v-14
		c0-3-0.4-5-1.2-5.9c-0.8-1-1.7-1.6-2.6-1.8V4.4z M435.7,35.6h-13.6V4.7c1.3,0,2.8-0.1,4.5-0.2c1.5-0.1,2.2-0.1,2.1-0.1v0.5
		c-1.5,0.4-2.6,1.2-3.2,2.3c-0.6,1.2-0.9,3-0.9,5.3v12.7c0,1.9,0.1,3.6,0.3,5.1c0.2,1.6,0.4,2.5,0.6,2.7c0.2,0.2,0.8,0.4,1.7,0.4
		h6.2c2.2,0,4-0.3,5.4-1c1.4-0.7,2.7-2,3.8-4h0.6c-0.1,0.9-0.6,2.4-1.3,4.5c-0.5,1.3-0.8,2.3-0.9,3.1
		C439.2,35.7,437.5,35.6,435.7,35.6z"/>
      <path id="L_00000181088136327688292700000007601280997313364383_" d="M445.7,4.4l6.2,0.4v30.9H450c-1.1,0-2.6,0.1-4.4,0.4v-0.5
		c1.4-0.6,2.4-1.5,3-2.7c0.5-1.3,0.8-3.3,0.8-6.1v-14c0-3-0.4-5-1.2-5.9c-0.8-1-1.7-1.6-2.6-1.8V4.4z M467.5,35.6H454V4.7
		c1.3,0,2.8-0.1,4.5-0.2c1.5-0.1,2.2-0.1,2.1-0.1v0.5c-1.5,0.4-2.6,1.2-3.2,2.3c-0.6,1.2-0.9,3-0.9,5.3v12.7c0,1.9,0.1,3.6,0.3,5.1
		c0.2,1.6,0.4,2.5,0.6,2.7c0.2,0.2,0.8,0.4,1.7,0.4h6.2c2.2,0,4-0.3,5.4-1c1.4-0.7,2.7-2,3.8-4h0.6c-0.1,0.9-0.6,2.4-1.3,4.5
		c-0.5,1.3-0.8,2.3-0.9,3.1C471,35.7,469.3,35.6,467.5,35.6z"/>
      <path id="E" d="M482.9,4.7h1v30.9h-1.9c-0.7,0-2.1,0.1-4.3,0.4v-0.5c1.1-0.3,1.9-0.8,2.4-1.5c0.6-0.7,0.9-1.6,1.1-2.5
		c0.2-1,0.3-3.2,0.3-6.4V12.7c0-2.8-0.4-4.8-1.1-5.8s-1.7-1.7-2.7-1.9V4.4C478.8,4.4,480.5,4.6,482.9,4.7z M497.7,35.6H486V4.7h8.5
		c1.6,0,4.6-0.1,9.1-0.4c0,0.6-0.1,1-0.1,1.3c0,0.4,0,1.3,0.1,2.7c0.1,1,0.1,1.5,0.1,1.6v0.7h-0.5c-0.7-1.7-1.5-2.8-2.4-3.3
		c-0.9-0.5-2.6-0.7-5.1-0.7h-4.7c-0.7,0-1.4,0-2.1,0.1c-0.4,0.7-0.6,2.2-0.6,4.7l-0.1,2v4.8h6.3c2.4,0,4-0.2,4.7-0.6
		c0.8-0.4,1.4-1.4,1.9-2.9h0.6c-0.2,1.8-0.2,3.3-0.2,4.6c0,0.8,0.1,1.9,0.2,3.2c0,0.4,0.1,1.2,0.1,2.3h-0.6
		c-0.2-1.7-0.7-2.9-1.6-3.5c-0.9-0.6-2.3-1-4.1-1h-7.4v6.4c0,2.5,0.1,4.6,0.4,6.5c0.8,0.1,1.7,0.2,2.6,0.2h4.5
		c2.4,0,4.2-0.3,5.5-0.8c1.3-0.6,2.6-1.8,3.8-3.8h0.6c-0.2,0.9-0.5,1.9-1,3.1c-0.5,1.5-0.9,2.8-1.1,4
		C501.4,35.7,499.4,35.6,497.7,35.6z"/>
      <path id="C_00000161611737816135148090000016301735701683393930_" d="M515.3,8.8c-2.2,2.9-3.3,6.7-3.3,11.5c0,5.6,1.4,9.8,4.1,12.6
		c-2.1-0.9-3.8-2.5-5-4.8c-1.2-2.3-1.8-4.9-1.8-7.7c0-2,0.3-3.8,1-5.4c0.6-1.6,1.3-2.9,2.2-3.8C513.1,10.2,514.1,9.5,515.3,8.8z
		 M539.6,29.2h0.6l-0.9,5c-1.8,0.7-3.6,1.2-5.2,1.6c-1.7,0.3-3.3,0.5-4.9,0.5c-4.7,0-8.4-1.4-11.1-4.1c-2.7-2.7-4-6.7-4-11.9
		c0-5.2,1.4-9.2,4.3-11.9c2.9-2.7,6.6-4.1,11.3-4.1c3.4,0,6.8,0.6,10.3,1.8c-0.2,2.5-0.2,3.9-0.2,4.4c0,0.4,0,0.8,0,1.2v0.7
		c0,1,0,2-0.1,2.9h-0.5c-1.2-5.9-4.6-8.8-10.1-8.8c-3.8,0-6.7,1.2-9,3.7c-2.2,2.4-3.3,5.9-3.3,10.5c0,4,1,7.3,3.1,9.8
		c2,2.5,5.1,3.8,9.2,3.8c2.3,0,4.3-0.4,6.2-1.3C537,31.8,538.5,30.6,539.6,29.2z"/>
      <path id="T_00000137105777671010780430000001156894848073846719_" d="M549.5,4.7h9.7v30.9h-0.9c-1.3,0-3.2,0.1-5.7,0.4v-0.5
		c1.6-0.4,2.7-1.2,3.3-2.5c0.6-1.2,0.9-3.3,0.9-6.2v-20h-7.5c-1.9,0-3.2,0.4-4,1.2c-0.8,0.8-1.4,2.3-2,4.4h-0.7c0-0.3,0-0.5,0-0.8
		c0-0.4,0-0.9,0.1-1.5c0.2-2.1,0.4-4,0.4-5.7c1,0.1,2.3,0.2,3.9,0.3L549.5,4.7z M562.1,35.6h-0.8V4.7h10.5c1.9,0,3.8-0.1,5.7-0.4
		c0,0.4,0,1-0.1,2c-0.1,0.9-0.1,2-0.1,3.4c0,0.9,0,1.7-0.1,2.6h-0.7c0-1.6-0.4-2.9-1.2-4c-0.8-1.1-2.4-1.6-5-1.6h-6.7v21
		c0,2.4,0.3,4.2,1,5.4c0.7,1.2,1.7,2,3.2,2.3v0.5C564.5,35.7,562.5,35.6,562.1,35.6z"/>
      <path id="I_00000147929999482755636010000010623615369472120722_" d="M587.8,4.7v30.9c-1.4,0-3.5,0.1-6.4,0.4v-0.5
		c1.5-0.4,2.5-1.2,3.1-2.5c0.6-1.3,0.9-3.6,0.9-7V12.8c0-2.5-0.3-4.4-0.9-5.6c-0.6-1.2-1.6-1.9-3.1-2.3V4.4
		C583.7,4.6,585.9,4.7,587.8,4.7z M590,35.6V4.7c2.4,0,4.6-0.1,6.4-0.4v0.5c-1.2,0.2-2.1,0.8-2.9,1.9c-0.8,1.1-1.2,3.3-1.2,6.7v14
		c0,3,0.4,5,1.2,6.1c0.8,1,1.8,1.7,2.8,2v0.5C594.6,35.7,592.4,35.6,590,35.6z"/>
      <path id="O_00000008111508020951830400000008790402953400053694_" d="M606.7,7.5c-2.1,3.3-3.2,7.6-3.2,12.7c0,4.7,1,8.8,3.1,12.2
		c-3.9-2.6-5.9-6.8-5.9-12.5C600.7,14,602.7,9.9,606.7,7.5z M631.4,20c0,3.6-0.5,6.6-1.6,9.1c-1.1,2.5-2.6,4.3-4.5,5.4
		c-1.9,1.2-4.2,1.8-6.7,1.8c-4.1,0-7.2-1.5-9.4-4.4c-2.2-2.9-3.3-6.9-3.3-12c0-5.3,1.1-9.4,3.4-12.2c2.3-2.8,5.4-4.2,9.3-4.2
		c4,0,7.2,1.4,9.5,4.2C630.3,10.6,631.4,14.6,631.4,20z M628.7,20c0-9.4-3.4-14-10.2-14c-6.7,0-10,4.8-10,14.4
		c0,9,3.4,13.5,10.1,13.5C625.3,33.9,628.7,29.3,628.7,20z M630.4,32.6c2.1-2.9,3.2-7.2,3.2-13.1c0-5.1-1.1-9.1-3.2-12
		c4,2.4,6,6.5,6,12.3C636.4,25.6,634.4,29.8,630.4,32.6z"/>
      <path id="N" d="M639.9,4.7l33.8,28.8v2.4h-0.6l-26.5-22.6V27c0,3,0.4,5,1.1,6.2c0.8,1.2,2,1.9,3.6,2.3v0.5c-2.9-0.2-5-0.4-6-0.4
		c-1.2,0-3,0.1-5.4,0.4v-0.5c1.7-0.4,2.8-1.2,3.4-2.4c0.6-1.2,0.9-3.3,0.9-6.3l0-15.3l-2.1-1.8c-2.9-2.5-5.4-4.1-7.4-4.7V4.4
		L639.9,4.7z M673.7,30.7l-30.4-26h3.5l24.6,21V13.5c0-2-0.1-3.6-0.4-4.7c-0.3-1-0.8-1.9-1.7-2.6c-0.8-0.7-1.9-1.1-3.2-1.3V4.4
		c0,0,0.4,0,1,0.1c2.6,0.2,4.3,0.3,5.2,0.3c0.8,0,2.8-0.1,6-0.4v0.5c-1,0.1-1.9,0.6-2.7,1.3c-0.7,0.7-1.2,1.5-1.5,2.5
		c-0.3,1-0.4,2.4-0.4,4.2V30.7z"/>
      <path id="Ex" d="M684.9,4.7v19.8h-1.1l-1.3-13.4c-0.2-2.1-0.5-3.6-0.7-4.4c-0.3-0.9-0.8-1.5-1.5-1.8V4.4
		C680.8,4.5,682.4,4.6,684.9,4.7z M685.9,36.4l-4.3-4.3l1.5-1.5l4.3,4.3L685.9,36.4z M688.7,33.5l-4.3-4.3l1.4-1.5l4.3,4.3
		L688.7,33.5z M689.1,12.5L688,24.6h-1.1V4.7c2.4-0.2,3.9-0.3,4.7-0.4v0.5c-0.6,0.3-1.1,0.8-1.4,1.6C689.7,7.3,689.4,9.3,689.1,12.5
		z"/>
    </g>
  </svg>
  );
};

export default BATCLogo;
